import clsx from "clsx";
import { Toaster, resolveValue, toast } from "react-hot-toast";
import styles from "./index.module.scss";

const OurToast = () => {
   return (
      <Toaster>
         {(t) => (
            <div className={styles.toasterContainer}>
               <div className={styles.siteTitle}>
                  newsbeast
                  <button className={styles.close} onClick={() => toast.remove(t.id)}>
                     <div dangerouslySetInnerHTML={{ __html: '<svg><use xlink:href="#iconClose" /></svg>' }} />
                  </button>
               </div>
               <div className={clsx(styles.content, styles[t.type])}>
                  {resolveValue(t.message, t)}
               </div>
            </div>
         )}
      </Toaster>
   )
}

export default OurToast;